// 日期相关函数
// const date = new Date()
// date.toLocaleString() "2020/11/11 上午10:30:03"
// date.toDateString() "Wed Nov 11 2020"
// date.toLocaleDateString() "2020/11/11"
// date.toLocaleTimeString() "上午10:30:03"

import dayjs from 'dayjs';

// 格式化时间戳
export function formatDate(val, format) {
  // const year = val.getFullYear();
  const month = val.getMonth() + 1;
  const date = val.getDate();
  // const day = val.getDay() + 1; // 星期
  if (new Date().getMonth() + 1 === month && date === new Date().getDate()) {
    return '今天';
  } else {
    return month + format + date;
  }
}

// 判断是否是今天
export function isToday(str) {
  return new Date(str).toLocaleDateString() === new Date().toLocaleDateString();
}

// 判断是否超过指定日期
export function isOverDay(str, comparedStr) {
  if (typeof str == 'string' && comparedStr == undefined) {
    return new Date().getTime() >= new Date(str.replace(/-/g, '/')).getTime();
  } else if (typeof str == 'string' && typeof comparedStr == 'string') {
    return (
      new Date(comparedStr.replace(/-/g, '/')).getTime() >=
      new Date(str.replace(/-/g, '/')).getTime()
    );
  }
}

// 格式化时间对象 为 0000-00-00 00:00:00
export function formatTime(time = new Date(), format = '/') {
  var year = time.getFullYear();
  var month = time.getMonth() + 1;
  month = String(month).padStart(2, '0');
  var theDate = time.getDate();
  theDate = String(theDate).padStart(2, '0');
  var hour = time.getHours();
  hour = String(hour).padStart(2, '0');
  var minute = time.getMinutes();
  minute = String(minute).padStart(2, '0');
  var second = time.getSeconds();
  second = String(second).padStart(2, '0');
  return (
    year +
    format +
    month +
    format +
    theDate +
    ' ' +
    hour +
    ':' +
    minute +
    ':' +
    second
  );
}

// 计算天数差
export function getDiffDay(date1, date2, abs = true) {
  if (typeof date1 == 'string') {
    date1 = date1.replace(/-/g, '/');
    date1 = new Date(date1);
  }
  if (typeof date2 == 'string') {
    date2 = date2.replace(/-/g, '/');
    date2 = new Date(date2);
  }
  // 只取年月日
  let d1 = new Date(date1.toLocaleDateString());
  let d2 = new Date(date2.toLocaleDateString());
  let diff = (d1 - d2) / (1000 * 60 * 60 * 24);
  if (abs) diff = Math.abs(diff);
  return (diff.toFixed(2) * 100) / 100;
}

// 得到 天数 后的时间戳
export function getFuture(date, diff, type = 'future') {
  let n = 1;
  if (type == 'past') n = -1;
  date = +new Date(date);
  return date + 1000 * 60 * 60 * 24 * diff * n;
}

// 获取 日期范围 内 的 每一天
export function getEveryDay(start, end) {
  let dateList = [];
  var startTime = new Date(start);
  var endTime = new Date(end);

  while (endTime.getTime() - startTime.getTime() >= 0) {
    var year = startTime.getFullYear();
    var month =
      startTime.getMonth() + 1 < 10
        ? '0' + (startTime.getMonth() + 1)
        : startTime.getMonth() + 1;
    var day =
      startTime.getDate().toString().length == 1
        ? '0' + startTime.getDate()
        : startTime.getDate();
    dateList.push(year + '-' + month + '-' + day);
    startTime.setDate(startTime.getDate() + 1);
  }
  return dateList;
}

// 2020-12-30 09:43:30 转成 9:43 13:43 昨天9:43 前天13:43 12-29 13:43
export function convertDate(date) {
  if (typeof date == 'string') {
    date = date.replace(/-/g, '/'); // 兼容ios
    date = new Date(date);
  }

  // 去0
  let time = date.getHours() + ':' + date.getMinutes();
  // 判断相差的天数
  let diff = Math.floor(getDiffDay(date, new Date()));
  let str = '';
  switch (diff) {
    case 0:
      str = time;
      break;
    case 1:
      str = '昨天' + time;
      break;
    case 2:
      str = '前天' + time;
      break;
    default:
      str = date.getMonth() + 1 + '/' + date.getDate() + ' ' + time;
      break;
  }
  return str;
}

// 秒转时分
export function convertTime(second_time) {
  var time = parseInt(second_time) + '秒';
  if (parseInt(second_time) > 60) {
    var second = parseInt(second_time) % 60;
    var min = parseInt(second_time / 60);
    time = min + '分' + second + '秒';

    if (min > 60) {
      min = parseInt(second_time / 60) % 60;
      var hour = parseInt(parseInt(second_time / 60) / 60);
      time = hour + '小时' + min + '分';

      if (hour > 24) {
        hour = parseInt(parseInt(second_time / 60) / 60) % 24;
        var day = parseInt(parseInt(parseInt(second_time / 60) / 60) / 24);
        time = day + '天' + hour + '小时' + min + '分';
      }
    }
  }

  return time;
}

export function dateDeal(val) {
  // 值为完整时间格式的字符串,去秒
  if (typeof val == 'string' && !isNaN(new Date(val)) && val.length == 19) {
    return dayjs(val).format('YYYY/MM/DD HH:mm');
  } else {
    return val;
  }
}
