<template>
  <div class="yiyu-box-section scroll">
    <div class="title" :style="{ color: color }" v-if="title">
      <div class="left">
        <!-- <div
          class="left-border"
          :style="{ borderLeft: border, borderBottom: borderBottom }"
        ></div> -->
        <div class="text" @click="ck(leftCk)">
          {{ title }}
          <van-icon v-if="tip" name="question-o" :color="$mainColor" />
        </div>
      </div>
      <div class="right">
        <div
          class="calendar"
          v-if="rightType == 'calendar'"
          @click="ck(rightType)"
        >
          <img
            class="w20 h20 img-filter"
            src="@/assets/icons/calendar.png"
            alt=""
          />
          {{ rightText }}
        </div>
        <div class="right-text" v-else @click="ck(rightCk)">
          {{ rightText }}
        </div>
        <slot name="right"></slot>
      </div>
    </div>
    <div class="content">
      <slot name="content"></slot>
    </div>
  </div>
</template>
<script>
import { MAIN_COLOR } from '@/utils/globar';
export default {
  name: 'boxSection',
  props: {
    border: {
      type: String,
      default: '3px solid' + MAIN_COLOR,
    },
    borderBottom: {
      type: String,
      default: '1px solid #f1f1f1',
    },
    title: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: MAIN_COLOR,
    },
    leftCk: {
      type: String,
      default: '',
    },
    rightText: {
      type: String,
      default: '',
    },
    rightType: String, // calendar
    rightColor: {
      type: String,
      default: MAIN_COLOR,
    },
    rightCk: {
      type: String,
      default: '',
    },
    tip: {
      // 是否显示提醒
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    ck(e) {
      this.$emit(e);
    },
  },
};
</script>
<style lang="less" scoped>
.yiyu-box-section {
  // padding: 0 0.5rem;
  background-color: #fff;

  .title {
    display: flex;
    justify-content: space-between;
    font-size: 1.1rem;
    align-items: center;
    font-weight: 500;

    .left {
      display: flex;
      // .left-border {
      //   width: 8px;
      //   height: 24px;
      // }
      .text {
        display: flex;
        align-items: center;
        font-size: 1.1rem;
        color: @titleColor;
        font-weight: 700;
      }
    }

    .right {
      .right-text {
        box-sizing: border-box;
        font-size: 1rem;
        white-space: nowrap;
      }

      .calendar {
        padding: 0px 1rem;
        height: 40px;
        background: rgba(@mainRgb, 0.06);
        opacity: 1;
        border-radius: 8px;
        font-size: 1rem;
        color: @mainColor;
        .FlexCenter;
      }
    }
  }
}

.scroll {
  scroll-behavior: smooth;
}
</style>
