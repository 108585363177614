<template>
  <div class="yiyu-form">
    <van-form
      center="true"
      error-message-align="center"
      :show-error="false"
      @submit="onSubmit"
      :input-align="inputAlign"
      ref="yiyuForm"
      @failed="onFailed"
    >
      <template v-for="(item, index) in formOpts">
        <boxSection
          v-if="item.show != false"
          :key="index"
          :title="item.title"
          color="#182A52"
          :border="'5px solid' + $mainColor"
          border-bottom="none"
        >
          <template #content>
            <div v-if="item.topSlot && item.show != false">
              <slot :name="item.topSlot"></slot>
            </div>
            <div v-for="(item1, index1) in item.child" :key="index1">
              <div v-if="item1.topSlot && item.show != false">
                <slot :name="item1.topSlot"></slot>
              </div>
              <!-- input-align="left" -->
              <!-- 备注框textarea -->
              <van-field
                class="textarea"
                v-if="item1.formType === 'textarea' && item1.show != false"
                :required="item1.required || false"
                :clearable="item1.readonly != true && item1.disabled != true"
                :autosize="true"
                show-word-limit
                :readonly="Boolean(item1.readonly)"
                :class="{ 'yiyu-form-readonly': item1.readonly }"
                :rows="item1.rows || 1"
                type="textarea"
                clear-trigger="always"
                v-model="item1.value"
                :name="item1.name"
                :placeholder="getPlaceholder(item1)"
                :rules="getRule(item1)"
                @input="
                  onInput($event, item1.name, item1.valueKey, item1.change)
                "
                :maxlength="item1.maxlength || 300"
              >
                <template #label>
                  {{ item1.label }}
                  <tip-popover v-model="item1.tipSlot" v-if="item1.tipSlot">
                  </tip-popover>
                </template>
                <template #extra v-if="item1.extra">
                  <div class="extra-txt">{{ item1.extra }}</div>
                </template>
              </van-field>
              <!-- number -->
              <van-field
                v-else-if="item1.formType === 'number' && item1.show != false"
                :required="item1.required || false"
                :clearable="item1.readonly != true && item1.disabled != true"
                autosize
                :readonly="Boolean(item1.readonly)"
                :disabled="Boolean(item1.disabled)"
                :class="{ 'yiyu-form-readonly': item1.readonly }"
                rows="1"
                :type="item1.type || 'number'"
                clear-trigger="always"
                v-model="item1.value"
                :name="item1.name"
                :label="item1.label"
                :placeholder="getPlaceholder(item1)"
                :rules="getRule(item1)"
                @input="
                  onInput($event, item1.name, item1.valueKey, item1.change)
                "
                maxlength="32"
              >
                <template #label>
                  {{ item1.label }}
                  <tip-popover v-if="item1.tipSlot" v-model="item1.tipSlot">
                  </tip-popover>
                </template>
                <template #extra v-if="item1.extra">
                  <div class="extra-txt">{{ item1.extra }}</div>
                </template>
              </van-field>
              <!-- 文件上传 此处只负责封装upload插槽，文件上传不在此处实现 -->
              <van-field
                class="file-upload"
                v-else-if="item1.formType === 'file' && item1.show != false"
                :name="item1.name"
                :label="item1.label"
                :rules="getRule(item1)"
              >
                <template #label>
                  {{ item1.label }}
                  <tip-popover v-if="item1.tipSlot" v-model="item1.tipSlot">
                  </tip-popover>
                </template>
                <template #input>
                  <slot :name="item1.slot">
                    <!-- 上传文件的组件 -->
                  </slot>
                </template>
              </van-field>
              <!-- 单选框radio -->
              <van-field
                class="radio"
                v-else-if="item1.formType === 'radio' && item1.show != false"
                name="radio"
                :label="item1.label"
                :required="item1.required || false"
                clearable
                autosize
                :readonly="Boolean(item1.readonly)"
                :placeholder="getPlaceholder(item1)"
                :rules="getRule(item1)"
              >
                <template #label>
                  {{ item1.label }}
                  <tip-popover v-if="item1.tipSlot" v-model="item1.tipSlot">
                  </tip-popover>
                </template>
                <template #left-icon> </template>
                <template #input>
                  <van-radio-group v-model="item1.value" direction="horizontal">
                    <!-- 支持数组格式 -->
                    <template v-if="Array.isArray(item1.opts)">
                      <van-radio
                        :name="item2.name"
                        v-for="(item2, index2) in item1.opts"
                        :key="index2"
                        @click="
                          onInput(
                            item2.name,
                            item1.name,
                            item1.valueKey,
                            item1.change
                          )
                        "
                        >{{ item2.label }}</van-radio
                      >
                    </template>
                    <!-- 支持对象格式 -->
                    <template v-else>
                      <van-radio
                        :name="key2"
                        v-for="(item2, key2, index2) in item1.opts"
                        :key="index2"
                        @click="
                          onInput(
                            key2,
                            item1.name,
                            item1.valueKey,
                            item1.change
                          )
                        "
                        >{{ item2 }}</van-radio
                      >
                    </template>
                  </van-radio-group>
                </template>
              </van-field>
              <!-- 只支持选择，不支持输入 -->
              <van-field
                v-else-if="item1.formType === 'select' && item1.show != false"
                :required="item1.required || false"
                :class="[item1.class]"
                readonly
                :clickable="item1.clickable != false"
                :is-link="item1.disabled != true"
                :disabled="item1.disabled == true"
                arrow-direction="down"
                :value="valDeal(item1.value)"
                :name="item1.name"
                :label="item1.label"
                :placeholder="getPlaceholder(item1)"
                :rules="getRule(item1)"
                @click="
                  item1.clickable != false ? item1.click() : function () {}
                "
              >
                <template #extra v-if="item1.slot">
                  <slot :name="item1.slot"></slot>
                </template>
              </van-field>
              <!-- 既支持输入也支持选择 -->
              <van-field
                v-else-if="
                  item1.formType === 'input-select' && item1.show != false
                "
                :required="item1.required || false"
                v-model="item1.value"
                :name="item1.name"
                :label="item1.label"
                :readonly="Boolean(item1.readonly)"
                :class="{ 'yiyu-form-readonly': item1.readonly }"
                :placeholder="getPlaceholder(item1)"
                :rules="getRule(item1)"
                :type="item1.type"
                @input="
                  onInput($event, item1.name, item1.valueKey, item1.change)
                "
                @click-right-icon="
                  typeof item1.click == 'function' ? item1.click() : undefined
                "
              >
                <template #label>
                  {{ item1.label }}
                  <tip-popover v-if="item1.tipSlot" v-model="item1.tipSlot">
                  </tip-popover>
                </template>
                <template #right-icon>
                  <van-icon name="arrow-down" />
                </template>
              </van-field>

              <van-field
                v-else-if="item1.show != false"
                :required="item1.required || false"
                v-model="item1.value"
                :name="item1.name"
                clearable
                :label="item1.label"
                :readonly="Boolean(item1.readonly)"
                :disabled="item1.disabled === true"
                :class="{
                  [item1.class]: true,
                  'yiyu-form-readonly': item1.readonly,
                }"
                :placeholder="getPlaceholder(item1)"
                :rules="getRule(item1)"
                :type="item1.type"
                :maxlength="item1.maxlength"
                @input="
                  onInput($event, item1.name, item1.valueKey, item1.change)
                "
                @click="textClick(item1.click)"
              >
                <template #label>
                  {{ item1.label }}
                  <tip-popover v-if="item1.tipSlot" v-model="item1.tipSlot">
                  </tip-popover>
                </template>
                <template #extra v-if="item1.slot">
                  <div class="extra-txt">{{ item1.extra }}</div>
                  <slot :name="item1.slot"></slot>
                </template>
                <template #extra v-else-if="item1.extra">
                  <div class="extra-txt">{{ item1.extra }}</div>
                </template>
              </van-field>
              <div v-if="item1.bottomSlot && item1.show != false">
                <slot :name="item1.bottomSlot"></slot>
              </div>
            </div>
            <div v-if="item.slot && item.show != false">
              <slot :name="item.slot"></slot>
            </div>
          </template>
          <template #right v-if="item.titleRight">
            <slot :name="item.titleRight"></slot>
          </template>
        </boxSection>
      </template>

      <slot name="footer"></slot>
    </van-form>
  </div>
</template>

<script>
import tipPopover from '@/components/feedback/tipPopover.vue';
import boxSection from '@/components/layout/boxSection.vue';
import { dateDeal } from '@/utils/date';
export default {
  name: 'yiyuForm',
  inheritAttrs: false, // 必不可少的代码,避免el-input的属性被添加到根元素div上
  props: {
    inputAlign: {
      type: String,
      default: 'center',
    },
    formOpts: {
      type: Array,
      default: () => [],
    },
    _this: {
      required: true,
    },
    formName: {
      type: String,
      default: 'form',
    },
  },
  components: {
    boxSection,
    tipPopover,
  },
  methods: {
    valDeal(val) {
      // formType 为 select 且 值为完整时间格式的字符串
      // 展示的时候不会展示秒
      return dateDeal(val);
    },
    textClick(clickFun) {
      if (clickFun !== undefined) {
        clickFun();
      }
    },
    onSubmit(values) {
      this.$emit('submit', values);
    },
    // 修改表单的值
    onInput(val, key, valueKey = [this.formName], fn) {
      if (valueKey[0] == this.formName && valueKey.length == 1) {
        this._this[this.formName][key] = val;
      } else {
        // 修改
        if (valueKey.length == 3) {
          this._this[valueKey[0]][valueKey[1]][valueKey[2]] = val;
        } else if (valueKey.length == 4) {
          this._this[valueKey[0]][valueKey[1]][valueKey[2]][valueKey[3]] = val;
        }
      }

      if (typeof fn == 'function') {
        fn(val);
      }
    },
    onFailed() {
      this.$toast.fail('请正确填写表单哦');
    },
    getRule(item) {
      // require的校验，统一在这里写即可，如果没有给的话
      let required = [{ required: true, message: item.label + '不能为空' }];
      if (item.rules) {
        if (!item.required) {
          return item.rules;
        } else {
          // 判断有没有提供 require 规则
          let flag = item.rules.some((item1) =>
            Object.keys(item1).includes('required')
          );

          if (flag) {
            return item.rules;
          } else {
            required.push(...item.rules);
            return required;
          }
        }
      } else {
        if (item.required) {
          return required;
        }
      }
    },
    getPlaceholder(item) {
      if (item.readonly) {
        return item.placeholder || '自动输入';
      }
      switch (item.formType) {
        case 'textarea':
          return item.placeholder
            ? item.placeholder + '(限' + (item.maxlength || 300) + '字)'
            : '请输入' + item.label + '(限' + (item.maxlength || 300) + '字)';
        default:
          if (item.placeholder) {
            return item.placeholder;
          } else {
            if (['radio', 'select'].includes(item.formType)) {
              return '请选择' + item.label;
            } else if (item.formType == 'input-select') {
              return `请选择${item.label}(可输入)`;
            } else {
              return '请输入' + item.label;
            }
          }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.yiyu-form {
  /deep/ .yiyu-box-section {
    // padding: 0.5rem 0;
    margin: 0;
    border-radius: 8px;
    .title {
      margin: 20px 0 10px;
      &:first-of-type {
        margin-top: 0;
      }
      .text {
        font-size: 1.1rem;
        line-height: 24px;
        color: @titleColor;
        font-weight: 500;
      }
      .right {
        padding-right: 16px;
      }
    }
  }

  .extra-txt {
    margin-left: 16px;
  }

  /deep/ .van-cell:last-child::after,
  .van-cell--borderless::after {
    display: inline-block;
  }

  /deep/ .van-cell::after {
    border-bottom: none;
    border-top: 1px solid @lineColor;
  }
  // :colon="item1.colon"
  /deep/.van-cell__title.van-field__label {
    // text-align: justify;
    width: auto;
    height: 48px;
    line-height: 48px;
    &::after {
      content: '';
      display: inline-block;
      width: 100%;
    }
  }

  // /deep/.van-field__control.van-field__control--right {
  //   .Ellipsis;
  // }

  // /deep/ .van-field__control.van-field__control--center {
  //   .Ellipsis;
  // }

  /deep/ .van-cell {
    height: 48px;
    box-sizing: border-box;
    align-items: center;

    &.textarea {
      height: auto;
      align-items: flex-start;

      .van-field__body {
        background: rgba(@bgRgb, 0.3);
        min-height: 3rem;
        .van-field__control {
          padding: 0.5rem;
        }

        .van-field__control.van-field__control--center {
          overflow: visible;
          text-overflow: initial;
          white-space: normal;
          text-align: left;
        }
      }
    }
  }

  .yiyu-form-readonly {
    color: @textColor;
    opacity: 0.7;

    /deep/.van-field__control {
      color: @textColor;
    }
  }

  /deep/.radio.van-cell {
    height: auto;

    .van-radio-group.van-radio-group--horizontal {
      flex-basis: 100%;
      // padding-left: 10%;// 显示容易有问题
      box-sizing: border-box;
    }

    .van-radio.van-radio--horizontal {
      box-sizing: border-box;
    }
  }

  .file-upload {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
  }

  /deep/ .yiyu-box-section {
    // padding: 0.5rem;
    margin: 12px 0 0;
    border-radius: 8px;
    &:first-of-type {
      margin-top: 0;
    }

    .title {
      .left-border {
        border: none !important;
        width: 0;
      }
      .text {
        font-size: 1.1rem;
        color: @titleColor;
        font-weight: 700;
      }
    }
  }
}
</style>
