<template>
  <div class="yy-gitd-box">
    <div
      class="grid-item"
      v-for="(item, index) in gridData"
      :key="index"
      :class="{ 'is-row': isRow(item, index) }"
    >
      <div class="label" v-if="item.label">{{ item.label }}</div>
      <div
        class="value"
        :class="{ ellipsis: ellipsis }"
        :style="{ color: item.color }"
        @click="click(item)"
      >
        <template v-if="item.slot">
          <slot :name="item.slot"></slot>
        </template>
        <template v-else-if="Array.isArray(item.value)">
          <div v-for="(item1, index1) in item.value" :key="index1">
            {{ item1 | checkEmpty }}
          </div>
        </template>
        <template v-else>
          {{ item.value | checkEmpty
          }}{{ item.value != undefined ? item.unit : '' }}
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import checkEmpty from '@/filters/checkEmpty'
// 某一行, grid 盒子合并
/**
 * example
 * | - | - |
 * | ----- |
 * | - | - |
 */
export default {
  name: 'gridBox',
  props: {
    value: {
      // 展示数据
      type: Array,
      default: () => [
        {
          label: 'label',
          value: 'value', // 支持 字符串 、 数组 格式 (数组换行展示)
          unit: 'unit', // 单位
          show: true, // 默认展示，非必需
          slot: undefined, // 替代 value 的插槽名
          color: '',
          click() {},
          isRow: false, // 是否占据一整行，默认奇数的情况下，最后一个数据占一整行
        },
      ],
    },
    ellipsis: {
      // 默认超出省略
      type: Boolean,
      default: true,
    },
  },
  computed: {
    gridData() {
      return this.value.filter((item) => item.show != false)
    },
  },
  filters: {
    checkEmpty,
  },
  methods: {
    click(item) {
      if (typeof item.click == 'function') {
        item.click()
      }
    },
    isRow(item, index) {
      if (item.isRow != undefined) return item.isRow
      else {
        let len = 0
        this.gridData.forEach((item) => {
          len++
          if (item.isRow == true) len++
        })
        if (len % 2 != 0 && index == len - 1) {
          return true
        } else {
          return false
        }
      }
    },
  },
}
</script>

<style lang="less" scoped>
.yy-gitd-box {
  display: grid;
  gap: 4px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  margin: 0.5rem 0;

  .grid-item {
    display: flex;
    align-items: center;

    &.is-row {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    .label {
      white-space: nowrap;
      .ShallowText;
      // font-size: 1rem;
      // font-size: 0.8rem;
      padding-right: 0.5rem;
      opacity: 0.7;
      &:after {
        content: ':';
      }
    }

    .value {
      // font-size: 1rem;
      // font-size: 0.8rem;
      color: @textColor;
    }
  }
}
</style>
