<template>
  <div class="yiyu-fixed-btn-wrap">
    <div class="yiyu-fixed-btn">
      <van-button
        class="left-btn"
        v-if="leftTxt"
        type="primary"
        plain
        @click.stop.prevent="emit('leftClick')"
        :disabled="leftLoading"
        :loading="leftLoading"
        >{{ leftTxt }}</van-button
      >
      <slot name="left"></slot>

      <slot v-if="$slots.default" />
      <van-button
        class="btn"
        v-else
        @click="emit('click')"
        :disabled="loading"
        :native-type="btnNativeType"
        :loading="loading"
        >{{ btnTxt }}</van-button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'fixedBtn',
  props: {
    btnTxt: {
      type: String,
      default: '确定',
    },
    btnNativeType: {
      type: String,
      default: 'button',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    leftTxt: String,
    leftLoading: Boolean,
  },
  methods: {
    emit(val) {
      this.$emit(val)
    },
  },
}
</script>

<style lang="less" scoped>
.yiyu-fixed-btn-wrap {
  height: 3rem;
  background: #fff;
  padding: 0.5rem 20px;
  // padding-bottom: env(safe-area-inset-bottom);
  // padding-bottom: constant(safe-area-inset-bottom);
  box-sizing: content-box;
}
.yiyu-fixed-btn {
  display: flex;
  box-sizing: border-box;
  padding: 0.5rem 20px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
  // padding-bottom: env(safe-area-inset-bottom);
  // padding-bottom: constant(safe-area-inset-bottom);

  .left-btn {
    margin-right: 8px;
    flex-basis: 30%;
    /deep/&.van-button {
      width: 100%;
      height: 3rem;
    }
  }

  /deep/.btn.van-button {
    flex-basis: 100%;
    background: @mainColor;
    color: #fff;
    font-size: 1.1rem;
    border-radius: 8px;
    .FlexCenter;
    height: 3rem;
  }
}
</style>
