<template>
  <van-popup v-model="showVisible" @close="cancel" position="bottom">
    <van-datetime-picker
      v-model="currentDate"
      @cancel="cancel"
      @confirm="confirm"
      :min-date="minDate"
      :max-date="maxDate"
      :type="type"
      :title="title"
      v-bind="$attrs"
    />
  </van-popup>
</template>

<script>
import { formatTime } from '@/utils/date'
export default {
  name: 'datetime',
  // 会触发的事件 confirm(value) cancel
  props: {
    show: {
      // 要添加 .sync 修饰符
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '标题',
    },
    date: {
      // sync
      type: [String, Date],
      default: () => new Date(),
    },
    minDate: {
      type: Date,
    },
    maxDate: {
      type: Date,
    },
    type: {
      type: String,
      default: 'datehour',
    },
  },
  data() {
    return {
      showVisible: this.show,
      currentDate: undefined,
    }
  },
  watch: {
    show(val) {
      this.showVisible = val
    },
    showVisible(val) {
      this.$emit('update:show', val)
    },
    date() {
      if (this.date) {
        if (typeof this.date == 'string') {
          this.currentDate = new Date(this.date && this.date.replace(/-/g, '/'))
        } else {
          this.currentDate = this.date
        }
      } else {
        this.currentDate = new Date()
      }

      if (this.type == 'time') {
        if (typeof this.date == 'string') {
          let date = this.date
          if (isNaN(Date.parse(this.date))) {
            date = '2021/01/01 ' + this.date
          }
          this.currentDate = new Date(date)

          this.currentDate =
            this.currentDate.getHours() + ':' + this.currentDate.getMinutes()
        }
      }
    },
  },
  created() {
    let date = new Date()
    if (this.type != 'time') {
      this.currentDate = date
    } else {
      this.currentDate = date.getHours() + ':' + date.getMinutes()
    }
  },
  methods: {
    // 组件优化
    confirm(value) {
      let time
      if (this.type == 'time') {
        time = value
      } else {
        time = formatTime(value)
      }

      if (this.type == 'datehour') {
        time = time.slice(0, 16)
      } else if (this.type == 'datetime') {
        time = time.slice(0, 16)
      } else if (this.type == 'time') {
        // time
      } else if (this.type == 'date') {
        time = this.$dayjs(value).format('YYYY-MM-DD')
      }

      this.$emit('update:date', time)
      this.$emit('confirm', time)
    },
    cancel() {
      this.showVisible = false
      this.$emit('cancel')
    },
  },
}
</script>

<style lang="less" scoped></style>
