<template>
  <div class="yiyu-data-field">
    <template v-if="type == 'table'">
      <div class="section" v-for="(items, key) in chunkDataObj" :key="key">
        <template v-for="(item, k) in items">
          <div class="field" :key="k" v-if="item.show != false">
            <div class="first" @click="ck(item.callback)">
              <span
                v-if="item.data != undefined"
                class="yiyu-data-field-title"
                :class="{ clickable: item.callback }"
                >{{ valDeal(item.data) }}</span
              >
              <span
                v-if="
                  (item.data == undefined || item.data === '') &&
                  Object.keys(item).length &&
                  !item.slot
                "
                class="yiyu-data-field-title text-c7"
                :class="{ clickable: item.callback }"
                >暂无</span
              >
              <span class="unit" v-if="item.unitText && item.data != undefined">
                {{ item.unitText }}</span
              >
            </div>
            <div class="second" v-if="item.descText && item.show != false">
              {{ item.descText }}
            </div>

            <slot :name="item.slot"></slot>
          </div>
        </template>
      </div>
    </template>
    <template v-else-if="type == 'row' || type == 'row-between'">
      <template v-for="(item, index) in normalDataObj">
        <div
          class="row"
          :key="index"
          v-if="item.show != false"
          :class="{ 'row-between': type == 'row-between' }"
        >
          <div class="list-item-label" v-if="item.descText">
            {{ item.descText }}
          </div>
          <slot :name="item.slot" v-if="item.slot"></slot>
          <div
            v-else
            class="list-item-value"
            @click="ck(item.callback)"
            :class="{ 'main-c': typeof item.callback == 'function' }"
          >
            {{ valDeal(item.data) | checkEmpty
            }}{{ item.data ? item.unitText : '' }}
          </div>
        </div>
      </template>
    </template>
    <template v-else-if="type == 'grid'">
      <div class="grid-box" :style="'--num:' + gridNum">
        <template v-for="(item, index) in chunkDataObj">
          <template v-if="index == 0">
            <template v-for="(item1, index1) in item">
              <div
                class="grid-header item"
                :key="index + 'head' + index1"
                v-if="item1.show != false"
                @click="ck(item.callback)"
                :class="{ 'main-c': typeof item.callback == 'function' }"
              >
                {{ item1.descText }}
              </div>
            </template>
          </template>
          <template v-for="(item1, index1) in item">
            <div
              class="item"
              :key="index + 'item' + index1"
              v-if="item1.show != false"
              :class="{ 'main-c': typeof item1.callback == 'function' }"
              @click="
                typeof item1.callback == 'function' ? item1.callback() : void 0
              "
            >
              <slot :name="item1.slot" v-if="item1.slot"> </slot>
              <template v-else>
                {{ item1.data ? item1.data : item1.unitText ? '0' : '暂无'
                }}{{ item1.unitText }}
              </template>
            </div>
          </template>
        </template>
      </div>
    </template>
    <template v-else-if="type == 'grid-box'">
      <grid-box :value="getGridBoxData()"></grid-box>
    </template>
  </div>
</template>
<script>
import checkEmpty from '@/filters/checkEmpty'
import gridBox from '@/components/layout/gridBox.vue'
import { dateDeal } from '@/utils/date'
export default {
  name: 'dataField',
  components: {
    gridBox,
  },
  props: {
    dataObj: {
      type: Array,
      default: () => {
        return [
          [
            // {
            //   data: '1000',
            //   unitText: '万',
            //   descText: '预定量',
            //   callback: 'showDesc',
            // },
          ],
        ]
      },
    },
    // 类型
    type: {
      type: String,
      default: 'table', // grid row grid-box row-between
    },
    gridNum: {
      type: [String, Number],
      default: '3',
    },
    // 是否开启过滤，即过滤掉data为undefined的值(0不会过滤)
    filter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  filters: {
    checkEmpty,
  },
  computed: {
    filterDataObj() {
      if (this.filter) {
        if (Array.isArray(this.dataObj[0])) {
          return this.dataObj.map((item) => {
            return item.filter(
              (item1) =>
                (item1.data != undefined && item1.data != '') ||
                item1.slot != undefined,
            )
          })
        } else {
          return this.dataObj.filter(
            (item1) =>
              (item1.data != undefined && item1.data != '') ||
              item1.slot != undefined,
          )
        }
      } else {
        return this.dataObj
      }
    },
    normalDataObj({ filterDataObj }) {
      return filterDataObj.flat()
    },
    chunkDataObj({ filterDataObj }) {
      let len = filterDataObj?.[0]?.length
      if (len < 3) {
        len = 3
      }
      let list = filterDataObj.flat().filter((item) => {
        return !item.hide
      })
      let newList = []
      let lineNum =
        list.length % len === 0
          ? list.length / len
          : Math.floor(list.length / len + 1)

      if (lineNum) {
        for (let i = 0; i < lineNum; i++) {
          let temp = list.slice(i * len, i * len + len)
          newList.push(temp)
        }

        while (newList[newList.length - 1].length < len) {
          newList[newList.length - 1].push({})
        }
      }

      return newList
    },
  },
  methods: {
    ck(e) {
      if (typeof e == 'function') {
        e()
      } else if (e) {
        this.$emit(e)
      }
    },
    getGridBoxData() {
      return this.normalDataObj.map((item) => {
        item.label = item.descText
        item.value = item.data
        item.unit = item.unitText
        return item
      })
    },
    valDeal(val) {
      return dateDeal(val)
    },
  },
}
</script>

<style lang="less" scoped>
.yiyu-data-field {
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  .section {
    display: flex;
    text-align: center;
    border-bottom: 1px dashed #f1f1f1;

    &:last-of-type {
      border: none;
    }
    .field {
      flex: 1;
      align-self: center;

      .second {
        padding: 0.5rem 0.5rem;
        .ShallowText;
        font-size: 1rem;
        margin-top: 6px;
        white-space: nowrap;
      }
      .yiyu-data-field-title {
        // color: @titleColor;
        font-size: 1rem;
        font-weight: bold;
      }
      .clickable {
        color: @mainColor;
      }
      .unit {
        color: @titleColor;
        font-size: 0.8rem;
        // font-weight: bold;
      }
    }
  }
}

.grid-box {
  /* 声明一个容器 */
  display: grid;
  /*  声明列的宽度  */
  grid-template-columns: repeat(var(--num, 3), auto);
  /*  声明行间距和列间距  */
  // grid-gap: 20px;
  /*  声明行的高度  */
  // grid-template-rows: 100px 200px;

  .item {
    text-align: center;
    color: @textColor;
    padding: 0.5rem 0;
  }

  .grid-header {
    font-weight: bold;
    opacity: 0.7;
  }
}

.list-item-label {
  .ShallowText;
  font-size: 1rem;
  padding-right: 0.5rem;
  opacity: 0.7;
  white-space: nowrap;
  &:after {
    content: ':';
  }
}
</style>
