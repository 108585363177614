<template>
  <div class="single-tip-popver">
    <van-popover
      v-model="showPopover"
      trigger="click"
      theme="dark"
      :get-container="getContainer"
      :placement="placement"
    >
      <div class="content">
        <slot name="default">{{ value }}</slot>
      </div>
      <template #reference>
        <van-icon name="question-o" />
      </template>
    </van-popover>
  </div>
</template>

<script>
export default {
  name: 'tipPopover',
  props: {
    placement: {
      // 弹出位置
      type: String,
      default: 'bottom-start', //默认底部左侧
    },
    value: {
      type: String,
    },
  },
  data() {
    return {
      showPopover: false,
    }
  },
  methods: {
    // 返回一个特定的 DOM 节点，作为挂载的父节点
    getContainer() {
      // return document.querySelector('.single-tip-popver');
      // return document.querySelector('.main-content');
      return document.querySelector('#app')
    },
  },
}
</script>

<style lang="less" scoped>
.single-tip-popver {
  display: inline-block;
  position: relative;
}
.van-popover__wrapper {
  display: flex;
  align-items: center;
}
</style>